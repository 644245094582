import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

const Index = () => {
  return (
    <Layout>
      <HeadMeta />
      <PageHeader>
        Guide to the paper of Members of the New South Wales Parliament, 1856-2006
        in the Mitchell Library, State Library of New South Wales.
      </PageHeader>
      <Breadcrumb route={[{ name: "Parliamentary Papers", href: "/" }]} />

      <p>
        The collections of the Mitchell Library, State Library of New South Wales
        include significant and wide ranging holdings of the personal papers of
        members of the New South Wales Parliament.
      </p>
      <p>
        The Library's collections comprise personal papers of Members of Parliament
        rather than official government records. Official records generated by the
        Government of NSW are held by{' '}
        <a href="https://www.records.nsw.gov.au/">State Records NSW</a>.
        Some papers of political
        parties and related organisations are held by the Library but these are not
        described in this Guide.
      </p>
      <p>
        The pictures collection of the Mitchell Library includes an extensive collection
        of portraits in various formats. A range of portraits of members of the
        New South Wales Parliament have been digitised for inclusion in the Guide.
      </p>
      <p>
        The Guide includes papers of members of the first Parliament after Responsible
        Government which sat on 22 May 1856, through to current members of the
        New South Wales Parliament.
      </p>
      <p>
        The guide describes the papers of:
      </p>
      <ul>
        <li>
          202 individual parliamentarians comprising 197 males and 5 females
        </li>
        <li>
          21 Premiers of NSW
        </li>
        <li>
          11 State parliamentarians who were also elected to the Federal Parliament
        </li>
        <li>
          3 Prime Ministers of Australia
        </li>
        <li>
          1 Governor General of Australia
        </li>
      </ul>
      <p>
        Collections listed in the Guide may include papers, photographs, printed material,
        newspaper cuttings, ephemera, audio tapes, video tapes and realia.
      </p>
      <h2>Browse</h2>
      <ul>
        <li>
          <Link to="/parliamentarians">NSW Parliamentarians</Link>
        </li>
        <li>
          <Link to="/parties">Political parties</Link>
        </li>
      </ul>
    </Layout>
  );
};

export default Index;
